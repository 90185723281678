<template>
  <div>
    <div class="category">
      <div class="service-type">
        <p>Service Category</p>
        <div class="service-type__title">
          <p>{{ category.name }}</p>
          <span class="trigger" @click="expand = !expand">
            {{ expand ? "Collapse" : "Expand" }}
            <i v-if="!expand" class="el-icon-arrow-down"></i>
            <i v-else class="el-icon-arrow-up"></i>
          </span>
        </div>
        <div class="service-type__types">
          <div v-for="(type, index) in category.types" :key="index">
            <span
              >Type {{ index + 1 }}
              <span class="edit" @click="emit('edit-service-type', index)"
                >Edit</span
              >
            </span>
            <p>{{ type.name }} ({{ type.variations.length }} options)</p>
            <ol v-if="expand">
              <li v-for="(variation, i) in type.variations" :key="i">
                {{ variation.name }}
              </li>
            </ol>
          </div>
        </div>
        <div
          v-if="expand"
          class="section-add"
          @click="emit('add-service-type')"
        >
          <span><i class="el-icon-plus"></i></span>
          <p>Add another service type</p>
        </div>
      </div>
      <div class="addons">
        <p>Add-ons</p>
        <div class="addons__title">
          <p v-if="!category.addons.length">
            No add-ons <span v-if="expand"> has been added</span>
          </p>
          <p v-else>Add-ons ({{ category.addons.length }} options)</p>
        </div>
        <div class="addons__types">
          <div>
            <ol v-if="expand">
              <li v-for="(addon, i) in category.addons" :key="i">
                {{ addon.name }}
              </li>
            </ol>
          </div>
        </div>
        <div v-if="expand" class="section-add" @click="emit('add-addons')">
          <span><i class="el-icon-plus"></i></span>
          <p>Add add-ons</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeautyCategory",
  props: {
    category: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      expand: false,
      showBeautyCategoryForm: false,
      categoryServiceType: {},
    };
  },
  computed: {},
  created() {},
  methods: {
    confirmDelete(type, index) {
      this.categoryServiceType = {
        ...type,
        index: index,
      };
      this.showConfirmDialog = true;
    },
    emit(action, serviceTypeIndex) {
      this.$emit("action", { action, serviceTypeIndex });
    },
  },
};
</script>

<style scoped lang="scss">
.category {
  margin-bottom: 30px;

  .service-type,
  .addons {
    background: rgba(0, 77, 41, 0.04);
    padding: 15px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    margin-bottom: 3px;

    > p {
      margin-bottom: 15px;
    }

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      p {
        color: var(--eden-grey-secondary);
        font-size: 0.875rem;
        font-weight: 500;
      }

      .trigger {
        color: var(--eden-green-primary);
        font-size: 0.875rem;
        cursor: pointer;

        i {
          margin-left: 5px;
          color: #7b8b79;
        }
      }
    }

    &__types {
      margin-top: 15px;
      padding-left: 30px;

      > div {
        margin-bottom: 25px;

        span {
          font-size: 12px;
          color: #798b83;

          .edit,
          .delete {
            display: inline-block;
            margin-left: 15px;
            color: var(--eden-green-primary);
            cursor: pointer;
          }

          .delete {
            color: var(--eden-danger);
          }
        }

        p {
          margin-top: 10px;
          color: #21312a;
          font-size: 0.875rem;
        }

        ol {
          padding-left: 20px;

          li {
            margin-top: 5px;
            color: var(--eden-grey-secondary);
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
</style>
