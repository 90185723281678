<template>
  <el-drawer
    :title="title"
    :visible.sync="shouldShow"
    direction="rtl"
    @close="closeEvent"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <el-row v-if="action === 'add'" type="flex">
            <el-col :span="24">
              <el-form-item
                label="Category Name"
                prop="name"
                :rules="validateField()"
              >
                <el-input
                  v-model="form.name"
                  type="text"
                  placeholder="What’s this category about? e.g 'Massage'"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <div v-for="(type, index) in form.types" :key="index">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Service Type"
                  :prop="'types.' + index + '.name'"
                  :rules="validateField()"
                >
                  <el-input
                    v-model="type.name"
                    type="text"
                    placeholder="e.g. a type of Massage is 'Premium Massage'"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Description"
                  :prop="'types.' + index + '.description'"
                  :rules="validateField()"
                >
                  <el-input
                    v-model="type.description"
                    type="textarea"
                    :rows="4"
                    placeholder="e.g. Deeply rejuvenating full body Deep Tissue, Swedish or Sports Massage. Not suitable for pregnant women."
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24" class="is-flex is-flex-column is-align-center">
                <div class="service-type-image">
                  <img
                    v-if="type.image"
                    :src="getImage(type.image)"
                    alt="Service Type"
                  />
                  <div class="trigger">
                    <eden-image-upload
                      :image-size="40"
                      :image-url.sync="type.image"
                      :image-text="'service type image'"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" justify="between" align="middle">
              <el-col :span="24">
                <div class="section">
                  <p>Service Type Variations</p>
                  <span>
                    e.g. if a customer wants “Premium Massage”, we currently
                    offer 2 options. List your Service Type variations with
                    details.
                  </span>
                </div>
              </el-col>
            </el-row>
            <div
              v-for="(variation, i) in type.variations"
              :key="i"
              class="variation fadeIn"
            >
              <el-row
                type="flex"
                align="middle"
                :gutter="20"
                class="flex-wrap mb-0"
              >
                <el-col :span="24">
                  <el-row type="flex" class="flex-wrap" :gutter="10">
                    <el-col :span="24" class="mb-1">
                      <el-form-item
                        label="Variation"
                        :prop="'types.' + index + '.variations.' + i + '.name'"
                        :rules="validateField()"
                      >
                        <el-input
                          v-model="variation.name"
                          type="text"
                          placeholder="e.g. Deluxe + 1hr"
                          :disabled="
                            !variation.disabled &&
                            action === 'edit-service-type'
                          "
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Price (KES)"
                        :prop="'types.' + index + '.variations.' + i + '.price'"
                        :rules="validateField()"
                      >
                        <el-input
                          v-model.number="variation.price"
                          v-number
                          type="text"
                          placeholder="e.g. 220"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Time (mins)"
                        :prop="'types.' + index + '.variations.' + i + '.time'"
                        :rules="validateField()"
                      >
                        <el-input
                          v-model="variation.time"
                          v-number
                          type="text"
                          placeholder="e.g. 80"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col
                  :span="24"
                  class="is-flex is-flex-column is-align-center"
                >
                  <div class="variation-image">
                    <img
                      v-if="variation.image"
                      :src="variation.image"
                      alt="Variation"
                    />
                    <div class="trigger">
                      <eden-image-upload
                        :image-size="40"
                        :image-url.sync="type.image"
                        :image-text="'variation image'"
                      />
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24" class="is-flex is-justify-center">
                  <div class="section-remove" @click="removeVariation(i)">
                    <span><i class="el-icon-close"></i></span>
                    <p>Remove variation</p>
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-row type="flex">
              <el-col :span="24" class="is-flex is-justify-center">
                <div class="section-add" @click="addVariation">
                  <span><i class="el-icon-plus"></i></span>
                  <p>Add a variation</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
    <div class="el-drawer--footer is-align-end">
      <el-button type="primary" @click="add">{{
        action.includes("add") ? "Add" : "Save changes"
      }}</el-button>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "BeautyCategoryForm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        name: "",
        types: [
          {
            name: "",
            description: "",
            image: "",
            variations: [
              {
                name: "",
                price: null,
                time: null,
                image: "",
              },
            ],
          },
        ],
      },
      uploadingTypeImage: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      switch (this.action) {
        case "add":
          return "Add Category";
        case "add-service-type":
          return "Add Category Service Type";
        case "edit-service-type":
          return "Edit Category Service Type";
        default:
          return "";
      }
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (this.action === "edit-service-type") {
          if (this.data.id) {
            this.form.types[0].id = this.data.id;
          }
          this.form.types[0].name = this.data.name;
          this.form.types[0].description = this.data.description;
          this.form.types[0].image = this.data.image;
          this.form.types[0].variations = this.data.variations.map(
            (variation) => {
              return {
                ...variation,
              };
            }
          );
        } else {
          setTimeout(() => {
            delete this.form.types[0].id;
            this.form.types[0].name = "";
            this.form.types[0].description = "";
            this.form.types[0].image = "";
            this.form.types[0].variations = [
              {
                name: "",
                price: null,
                time: null,
                image: "",
              },
            ];
          }, 100);
        }
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    addVariation() {
      this.form.types[0].variations.push({
        disabled: "false",
        name: "",
        price: "",
        time: "",
        image: "",
      });
    },
    removeVariation(i) {
      if (this.form.types[0].variations.length > 1) {
        this.form.types[0].variations.splice(i, 1);
      } else {
        this.$message.info("You need to have at least 1 variation added.");
      }
    },
    add() {
      const data = JSON.parse(JSON.stringify(this.form));
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.$emit("form-action", data);
        this.closeEvent();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.variation {
  background: #004d2908;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.variation-image,
.service-type-image {
  margin-top: 20px;
  height: 100px;
  width: 100%;
  border: 1px dashed #798b8350;
  background: #ffffff;
  border-radius: 8px;
  position: relative;

  img {
    border: 1px dashed #798b8350;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .trigger {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.service-type-image {
  margin-top: 0;
  margin-bottom: 20px;
}

.section {
  p {
    font-size: 0.9rem;
    line-height: 1.4;
    color: #798b83;
    margin-bottom: 5px;
  }

  span {
    color: #21312a;
    font-size: 0.9rem;
    opacity: 0.5;
  }
}
</style>
