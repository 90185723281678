<template>
  <el-drawer :visible.sync="shouldShow" direction="rtl" @close="closeEvent">
    <template slot="title">
      <h6 class="text-black">{{ title }}</h6>
    </template>
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <div v-for="(addon, index) in form.addons" :key="index" class="addon">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Add-on Name or Title"
                  :prop="'addons.' + index + '.name'"
                  :rules="validateField()"
                >
                  <el-input
                    v-model="addon.name"
                    type="text"
                    placeholder="e.g Remove existing Gel/Acrylic"
                    :disabled="!addon.disabled"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="10">
              <el-col :span="12">
                <el-form-item
                  label="Price (KES)"
                  :prop="'addons.' + index + '.price'"
                  :rules="validateField()"
                >
                  <el-input
                    v-model.number="addon.price"
                    v-number
                    type="text"
                    placeholder="e.g. 220"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Time to serve (mins)"
                  :prop="'addons.' + index + '.time_to_serve'"
                  :rules="validateField()"
                >
                  <el-input
                    v-model.number="addon.time_to_serve"
                    v-number
                    type="text"
                    placeholder="e.g. 15"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24" class="is-flex is-justify-center">
                <div class="section-remove--icon" @click="removeAddOn(index)">
                  <span><i class="el-icon-close"></i></span>
                  <p>Remove addon</p>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-row type="flex">
            <el-col :span="24" class="is-flex is-justify-center">
              <div class="section-add--icon" @click="addAddon">
                <span><i class="el-icon-plus"></i></span>
                <p>Add an add-on</p>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="el-drawer--footer is-align-end">
      <el-button type="primary" @click="add">{{
        action.includes("add") ? "Add" : "Save changes"
      }}</el-button>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "BeautyCategoryAddon",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      form: {
        addons: [],
      },
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return `${this.action === "add" ? "Add" : "Edit"} Add-ons`;
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (!this.data.length) {
          this.form.addons = [
            {
              disabled: "false",
              name: "",
              price: null,
              time_to_serve: null,
            },
          ];
          return;
        }
        this.form.addons = this.data.map((addon) => {
          return {
            name: addon.name,
            price: addon.price,
            time_to_serve: addon.time_to_serve,
          };
        });
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    addAddon() {
      this.form.addons.push({
        disabled: "false",
        name: "",
        price: "",
        time_to_serve: "",
      });
    },
    removeAddOn(index) {
      this.form.addons.splice(index, 1);
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.$emit("add", this.form.addons);
        this.closeEvent();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.addon {
  background: #004d2908;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}
</style>
