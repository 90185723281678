<template>
  <div>
    <div v-loading="prefilling" class="eden-page-form">
      <el-form :model="form" label-position="top" ref="form">
        <eden-form-section
          title="Service type information"
          subtitle="Basic information about this particular service type"
        >
          <template slot="form-fields">
            <el-row type="flex">
              <el-col :md="24">
                <el-form-item label="Service">
                  <el-input type="text" v-model="form.service" disabled />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :md="24">
                <el-form-item
                  label="Service type name"
                  prop="service_type_name"
                  :rules="validateField()"
                >
                  <el-input type="text" v-model="form.service_type_name" />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section
          title="Categories & Products"
          subtitle="Information about beauty options & pricing specification"
        >
          <template slot="form-fields">
            <beauty-category
              v-for="(category, index) in form.beauty_category"
              :key="index"
              :category="category"
              @action="beautyCategoryAction($event, index)"
            />
            <el-row type="flex">
              <el-col :span="24" class="is-flex is-justify-center">
                <div
                  class="section-add"
                  @click="
                    beautyCategoryAction(
                      { action: 'add', beautyCategoryAction: null },
                      null
                    )
                  "
                >
                  <span><i class="el-icon-plus"></i></span>
                  <p>Add a category</p>
                </div>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section title="" subtitle="">
          <template slot="form-fields">
            <div class="eden-page-form__actions">
              <el-button plain @click="cancel">Cancel</el-button>
              <el-button
                v-if="action === 'add'"
                type="primary"
                :loading="adding"
                @click="add"
                >Add service type</el-button
              >
              <el-button
                v-else
                type="primary"
                :loading="updating"
                @click="update"
                >Save changes</el-button
              >
            </div>
          </template>
        </eden-form-section>
      </el-form>
    </div>
    <beauty-category-form
      :show.sync="showBeautyCategoryForm"
      :action="category.action"
      :data="category.data"
      @form-action="formAction"
    />
    <beauty-category-addon
      :show.sync="showBeautyCategoryAddon"
      :action="categoryAddon.action"
      :data="categoryAddon.data"
      @add="addAddons"
    />
  </div>
</template>

<script>
import BeautyCategoryForm from "@/components/Services/Beauty/BeautyCategoryForm";
import BeautyCategory from "@/components/Services/Beauty/BeautyCategory";
import BeautyCategoryAddon from "@/components/Services/Beauty/BeautyCategoryAddon";

import service from "@/requests/services/service";

export default {
  name: "BeautyForm",
  components: {
    BeautyCategoryAddon,
    BeautyCategory,
    BeautyCategoryForm,
  },
  props: {
    action: {
      type: String,
      default: "add",
    },
    serviceTypeId: {
      type: [String, Number],
      default: "",
    },
    serviceType: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      prefilling: false,
      adding: false,
      updating: false,
      form: {
        service: "Beauty",
        service_type_name: "",
        service_type_category: "single",
        limit: "",
        beauty_category: [],
      },
      showBeautyCategoryForm: false,
      category: {
        action: "add",
        index: null,
        serviceTypeIndex: null,
        data: {},
      },
      showBeautyCategoryAddon: false,
      categoryAddon: {
        index: null,
        data: [],
      },
    };
  },
  created() {
    if (this.action === "edit") {
      this.prefilling = true;
      this.form = this.serviceType;
      setTimeout(() => {
        this.prefilling = false;
      }, 1000);
    }
  },
  methods: {
    beautyCategoryAction({ action, serviceTypeIndex }, categoryIndex) {
      switch (action) {
        case "add":
          this.setCategory("add", null, null);
          break;
        case "add-service-type":
          this.setCategory("add-service-type", categoryIndex, null);
          break;
        case "edit-service-type":
          this.setCategory(
            "edit-service-type",
            categoryIndex,
            serviceTypeIndex
          );
          break;
        case "add-addons":
          this.categoryAddon.index = categoryIndex;
          this.categoryAddon.data =
            this.form.beauty_category[categoryIndex].addons;
          this.showBeautyCategoryAddon = true;
          break;
        default:
          break;
      }
    },
    setCategory(action, categoryIndex, serviceTypeIndex) {
      this.category.action = action;
      this.category.index = categoryIndex;
      this.category.serviceTypeIndex = serviceTypeIndex;
      this.category.data =
        action === "edit-service-type"
          ? {
              ...this.form.beauty_category[categoryIndex].types[
                serviceTypeIndex
              ],
            }
          : {};
      this.showBeautyCategoryForm = true;
    },
    formAction(category) {
      const { action } = this.category;
      switch (action) {
        case "add":
          this.addCategory(category);
          break;
        case "add-service-type":
          this.addServiceType(category);
          break;
        case "edit-service-type":
          this.updateServiceType(category);
          break;
        default:
          break;
      }
    },
    addCategory(category) {
      this.form.beauty_category.push({
        ...category,
        addons: [],
      });
    },
    addServiceType(category) {
      const { index } = this.category;
      this.form.beauty_category[index].types.push(category.types[0]);
    },
    updateServiceType(category) {
      const { index, serviceTypeIndex } = this.category;
      this.$set(this.form.beauty_category[index].types, serviceTypeIndex, {
        ...category.types[0],
      });
    },
    addAddons(addons) {
      const { index } = this.categoryAddon;
      this.form.beauty_category[index].addons = addons;
    },
    cancel() {
      this.$router.push({ name: "services.service", service: "meal" });
    },
    payload() {
      return {
        ...this.form,
        beauty_category: this.form.beauty_category.map((category) => {
          return {
            ...category,
            types: category.types.map((type) => {
              return {
                ...type,
                variations: type.variations.map((variation) => {
                  return {
                    ...variation,
                    time: variation.time * 60,
                  };
                }),
              };
            }),
            addons: category.addons.map((addon) => {
              return {
                ...addon,
                time_to_serve: addon.time_to_serve * 60,
              };
            }),
          };
        }),
      };
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        const payload = this.payload();
        service
          .serviceTypeAdd(payload)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.success(message);
            } else {
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        const payload = this.payload();

        service
          .serviceTypeUpdate(this.serviceTypeId, payload)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.success(message);
            } else {
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    success(message) {
      this.adding = false;
      this.updating = false;
      this.$message.success(message);
      this.$router.push({
        name: "services.service",
        params: { service: "beauty" },
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
